@font-face {
    font-family: "Söhne";
    src: url("./fonts/Söhne.otf");
}

body {
    margin: 0;
    padding: 0;
    /* background: linear-gradient(to left, #ba7453, #d1a984); */
    background: linear-gradient(to left, #dd751a, #fad1ac);
    background: rgb(0,0,0);
    /* background: rgb(52, 63, 77); */
    /* height: 100vh; */
    color: #fafafa;
    /* overflow: hidden; */
}
/* background-color: rgb(56, 54, 79) */
/* background-color: rgb(52, 63, 77)*/
/* background-color: rgb(255, 255, 255); */
/* background-color: rgb(29, 35, 42); */
/* margin: 100px 0 40px; in h1 */

* {
    font-family: ui-monospace,
        SFMono-Regular,
        Menlo,
        Monaco,
        Consolas,
        "Liberation Mono",
        "Courier New",
        monospace;;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

/* ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
}

::-webkit-scrollbar-thumb {
    background: #fafafa;
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
    background: linear-gradient(to left, #ba7453, #d1a984);
} */

html {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.sectionBodyExp {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
}
.sectionBodyExp>.sectionExpDiv {
    width: 300px;
    /* height: 400px; */
    position: relative;
    max-height: 90vh;
    margin: 20px;
    background: var(--white);
    overflow: hidden;
}

.imgTag {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

/* Home Screen 1 */
.homeHolder {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.homeHolder > section{
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.sectionHome {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.customHero {
    height: 100%;
    width: 100%;
    position: relative;
}

.customHero .imgHome {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Home screen 2 */

.loader {
    position: relative;
    width: 240px;
    height: 130px;
    margin-bottom: 10px;
    border: 1px solid #d3d3d3;
    padding: 15px;
    background-color: #e3e3e3;
    overflow: hidden;
}

.loader:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(110deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, rgba(227, 227, 227, 0.5) 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
    animation: gradient-animation_2 1.2s linear infinite; */
}

.loader .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.loader .wrapper>div {
    background-color: #cacaca;
}

.loader .line{
    position: absolute;
    top: var(--top);
    left: 0px;
    height: 10px;
    width: var(--width);
}

@keyframes gradient-animation_2 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.scrollHide::-webkit-scrollbar {
    display: none;
}

.scrollHide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.smbtn {
    display: flex;
    place-items: center;
    background: #e3edf7;
    border-radius: 10px;
    /* box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.7); */
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: transform 0.5s;
}

.smbtn:hover {
    /* box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
        inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
        -0.5px -0.5px 0px rgba(255, 255, 255, 1),
        0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
        0px 12px 10px -10px rgba(0, 0, 0, 0.05); */
    border: 1px solid rgba(0, 0, 0, 0.1);
    transform: translateY(0.5em);
}

.smbtn svg {
    transition: transform 0.5s;
}

.smbtn:hover svg {
    transform: scale(0.9) rotate(360deg);
    fill: #333333;
}

.smbtn img {
    transition: transform 0.5s;
}

.smbtn:hover img {
    transform: scale(0.9) rotate(360deg);
    fill: #333333;
}

input:valid {
    outline: none;
    /* border: 1.5px solid #1a73e8; */
}

textarea:valid{
    outline: none;
}

.sendButton {
    font-family: inherit;
    font-size: 20px;
    background: royalblue;
    color: white;
    /* padding: 0.7em 1em; */
    /* padding-left: 0.9em; */
    display: flex;
    align-items: enter;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
}

.sendButton .sendSpan {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
}

.sendButton .sendSvg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
}

.sendButton:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
}

.sendButton:hover .sendSvg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.sendButton:hover .sendSpan {
    transform: translateX(50em);
}

.sendButton:active {
    transform: scale(0.95);
}

@keyframes fly-1 {
    from {
        transform: translateY(0.1em);
    }

    to {
        transform: translateY(-0.1em);
    }
}

.infinite-scroll-body {
    font-family: "Montserrat", sans-serif;
    /* background: #1e293b; */
    background-color: black;
    /* background: #f0de7a; */
    color: #f8fafc;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tag-list {
    width: 30rem;
    width: 50rem;
    max-width: 100vw;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem 0;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
}

.loop-slider .inner {
    display: flex;
    width: fit-content;
}

.tag {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    color: #e2e8f0;
    font-size: 0.9rem;
    /* background-color: #334155; */
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.2rem 1.5rem rgba(0, 0, 0, 0.4);
}

.fade {
    pointer-events: none;
    background: linear-gradient(90deg, #000000, transparent 30%, transparent 70%, #000000);
    /* background: linear-gradient(90deg, #f0de7a, transparent 30%, transparent 70%, #f0de7a); */
    position: absolute;
    inset: 0;
}

@keyframes loop-mobile {
    0% {
        transform: translateX(70%);
    }

    100% {
        transform: translateX(-70%);
    }
}

@keyframes loop-desktop {
    0% {
        transform: translateX(30%);
    }

    100% {
        transform: translateX(-30%);
    }
}

/* TEMP CSS (Might be removed) */

.resume{
    scrollbar-width: 0;
}

.notification {
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: max-content;
    border-radius: 6px;
    background-color: #313e2c;
    color: #aaec8a;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(30px);
    opacity: 0;
    /* visibility: hidden; */
    animation: fade-in 3s linear;
}

.notification_icon {
    height: 26px;
    width: 26px;
    margin-right: 4px;
}

.notification_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 8px;
}

.notification_progress {
    position: absolute;
    Left: 4px;
    bottom: 4px;
    width: calc(100% - 8px);
    height: 3px;
    transform: scale(0);
    transform-origin: left;
    background: linear-gradient(to right, #313e2c, #aaec8a);
    border-radius: inherit;
    animation: progress 2.5s 0.3s linear;
}

@keyframes fade-in {
    5% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY (0);
    }
}

@keyframes progress {
    to{
        transform: scaleX(1);
    }
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: white;
    transform-origin: 0%;
}

.glow {
    border-radius: 14px;
    box-shadow: rgba(74, 0, 255, 0.8) 0 0 90px 33px;
}

.gpt {
    /* font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
    font-family: "Söhne",
        "ui-sans-serif",
        "system-ui",
        -apple-system,
        "Segoe UI",
        "Roboto",
        "Ubuntu",
        "Cantarell",
        "Noto Sans",
        sans-serif,
        "Helvetica Neue",
        Arial,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";
}

.typeWrite {
    font-family: arial, 
        "Inter",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "Noto Sans",
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji" !important;
}